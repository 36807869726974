import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const TestimonialsImage = ({ className }) => {
    const data = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "testimonials.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 650, quality: 50) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <Img
            fluid={data.image.childImageSharp.fluid}
            className={className}
            alt="tick next to happy face"
        />
    );
};

TestimonialsImage.propTypes = {
    className: PropTypes.string,
};

TestimonialsImage.defaultProps = {
    className: '',
};

export default TestimonialsImage;
