import React from 'react';
import PropTypes from 'prop-types';

import './page-section.scss';

const PageSection = ({ children, className }) => (
    <article className={`page-section ${className}`}>
        {children.map((child, ix) => {
            const modifiedClassName = `${child.props.className} page-section__tile`;
            const props = {
                ...child.props,
                className: modifiedClassName,
                key: ix,
            };

            const modifiedChild = React.cloneElement(child, props);
            return modifiedChild;
        })}
    </article>
);

PageSection.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

PageSection.defaultProps = {
    className: '',
};

export default PageSection;
