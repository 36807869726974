import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import './testimonial.scss';

const Testimonials = ({ className, testimonialData }) => (
    <div className={`testimonial ${className}`}>
        <div className="testimonial__rating">
            <FontAwesomeIcon icon={faStar} className="testimonial__star" />
            <FontAwesomeIcon icon={faStar} className="testimonial__star" />
            <FontAwesomeIcon icon={faStar} className="testimonial__star" />
            <FontAwesomeIcon icon={faStar} className="testimonial__star" />
            <FontAwesomeIcon icon={faStar} className="testimonial__star" />
        </div>
        <div className="testimonial__text">{testimonialData.text}</div>
        <div className="testimonial__customer">
            <div className="testimonial__name bold">{testimonialData.name}</div>
            <div className="testimonial__location">
                {testimonialData.location}
            </div>
        </div>
    </div>
);

Testimonials.propTypes = {
    className: PropTypes.string,
    testimonialData: PropTypes.shape({
        text: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        rating: PropTypes.number.isRequired,
    }).isRequired,
};

Testimonials.defaultProps = {
    className: '',
};

export default Testimonials;
