import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import TestimonialsImage from '../components/testimonials-image';
import PageSection from '../components/page-section';
import Testimonial from '../components/testimonial';

import testimonialsData from '../data/testimonials.json';

import './testimonials.scss';

const seo = {
    title: 'Testimonials',
    description: `Reviews, ratings and testimonials for JPF Solutions Ltd from our customers. Our reputation means everything to us, and we have a proven track record of providing an excellent service to all of our customers.`,
};

const TestimonialsPage = () => (
    <Layout
        pageId="testimonials"
        layoutClassName="testimonials"
        title="Testimonials"
    >
        <SEO
            title={seo.title}
            description={seo.description}
            relativeCanonicalSlug="testimonials"
        />

        <PageSection className="intro">
            <div className="page-section__text page-section__text--right">
                <p className="page-section__tagline">
                    Our reputation means everything to us
                </p>
                <p className="page-section__sub-tagline">
                    We have a proven track record of providing an excellent
                    service to all of our customers
                </p>
                <p className="page-section__sub-tagline">
                    Check out what some of our customers have to say about us
                    below
                </p>
            </div>
            <div className="page-section__image intro__image-container">
                <div className="intro__image">
                    <TestimonialsImage />
                </div>
            </div>
        </PageSection>
        <div className="testimonials__list">
            {testimonialsData.map((testimonialData, ix) => (
                <Testimonial
                    key={testimonialData.name}
                    className={`testimonials__testimonial ${
                        ix === testimonialsData.length - 1
                            ? 'testimonials__testimonial--last'
                            : ''
                    }`}
                    testimonialData={testimonialData}
                />
            ))}
        </div>
    </Layout>
);

export default TestimonialsPage;
